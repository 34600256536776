<template>
  <div class="passwordResetConfirm" >
    <div class="container">
      <h1 class="gavc-h1 text-center">Réinitialisation de votre mot de passe</h1>
      <div v-if="passwordReset">
        <div class="text-center">
          <div>
          </div>
          <h2 class="gavc-h2 mt-3">
            Votre mot de passe a bien été modifié
          </h2>
        </div>
        <div class="row mt-4">
          <div class="col-12 text-center">
            <button class="gavc-btn" @click="$router.push('/se-connecter')">
              Connectez-vous
            </button>
          </div>
        </div>
      </div>
      <div v-else>
        <b-form v-on:submit.prevent="confirmResetPassword()" class="mt-4">

          <div
            class="gavc-field"
            v-bind:class="{ 'is-error': $v.password.$dirty ? $v.password.$error : null  }">
              <label class="gavc-label" for="input-password">
                  Votre mot de passe
                  <span class="gavc-required"> * </span>
              </label>
              <input
                autocomplete="on"
                class="gavc-input"
                id="input-password"
                placeholder="Mot de passe"
                required
                type="password"
                v-model="password">
          </div>
          <p class="gavc-p gavc-text-rose" v-if="password && !$v.password.password_validation">
            Votre mot de passe doit contenir au moins 8 caractères, dont une majuscule, un chiffre et un caractère spécial.
          </p>

          <div
            class="gavc-field"
            v-bind:class="{ 'is-error': $v.passwordConfirmation.$dirty ? $v.passwordConfirmation.$error : null  }">
              <label class="gavc-label" for="input-password-confirmation">
                  Votre mot de passe
                  <span class="gavc-required"> * </span>
              </label>
              <input
                autocomplete="on"
                class="gavc-input"
                id="input-password-confirmation"
                placeholder="Mot de passe"
                required
                type="password"
                v-model="passwordConfirmation">
          </div>
          <p class="gavc-p gavc-text-rose" v-if="passwordConfirmation && !$v.passwordConfirmation.sameAsPassword">
            Veuillez saisir deux mots de passe identiques
          </p>

          <p class="gavc-p gavc-text-rose" v-if="errorPasswordMessage">
            {{errorPasswordMessage}}
          </p>
          <div class="text-center">
            <button
              type="submit"
              class="gavc-btn mt-3"
              :disabled="$v.password.$invalid || $v.passwordConfirmation.$invalid || isResettingPassword">
                Réinitialiser mon mot de passe
            </button>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, sameAs } from 'vuelidate/lib/validators'

import { APIDisconnectedService } from '@/api/APIDisconnected'
import { password_validation } from '@/validators/validators'


const apiAuth = new APIDisconnectedService()


export default {
  name: 'PasswordResetConfirm',
  mixins: [
    validationMixin
  ],
  data: function () {
    return {

      confirmResetPasswordToken: '',
      errorPasswordMessage: '',
      isResettingPassword: false,
      password: '',
      passwordConfirmation: '',
      passwordReset: false
    }
  },
  mounted: function() {
    this.confirmResetPasswordToken = this.$route.query.token
  },
  validations: {
    password: {
      required,
      maxLength: maxLength(200),
      minLength: minLength(8),
      password_validation
    },
    passwordConfirmation: {
      required,
      sameAsPassword: sameAs('password'),
      maxLength: maxLength(200),
      minLength: minLength(2)
    }
  },

  methods: {
    confirmResetPassword () {
      this.isResettingPassword = true
      this.$store.commit('loadFromAPI')
      apiAuth.confirmResetPassword(this.confirmResetPasswordToken, this.password )
      .then(() => {
          this.passwordReset = true
        }, (error) => {
          if (error.response.status == 400) {
            this.errorPasswordMessage = error.response.data.email[0]
          }
          else {
            this.$store.commit('openGlobalErrorModal')
          }
        })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
        this.isResettingPassword = false
      })
    }
  }
}
</script>
